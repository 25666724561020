<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-4">
              <h3 class="card-title font-weight-bold" style="font-size: 25px">Detalles del Resgistro</h3>
              <vs-button @click="$router.push('/helex/Erp/Contabilidad/RegistrosContables/CrearRegistros')">Registrar Movimiento</vs-button>
            </div>
          </template>
          <div class="p-5 d-flex justify-content-center">
            <div class="bg-body p-3 border" style="width: 35rem; border-radius: 1rem; box-shadow: 0px 1px 13px 5px rgb(0 0 0 / 0.1)">
              <div class="d-flex align-items-center" style="gap: 2rem">
                <vs-avatar circle size="70">
                  <img v-if="empresa.urlLogo !== null" :src="empresa.urlLogo" alt="Logo de la empresa" />
                  <ion-icon style="font-size: 3rem" v-else name="business-outline"></ion-icon>
                </vs-avatar>
                <div>
                  <h4 class="card-title m-0">{{ empresa.nombre }}</h4>
                  <p class="m-0">{{ empresa.direccionDelDomicilioPrincipal }}</p>
                  <p class="m-0">CIF/NIF: {{ empresa.nit }}</p>
                  <p class="m-0">{{ empresa.correo }}</p>
                </div>
              </div>
              <hr style="height: 0.8px; background: var(--dark)" class="w-100 mb-3" />
              <div>
                <h4 class="card-title m-0 pb-3">Registro</h4>
                <article class="row px-3 mt-1">
                  <h4 class="col-4 p-0 m-0">Fecha</h4>
                  <p class="col-8 p-0 m-0" style="border-bottom: 1px dotted">{{ movimiento.fecha }}</p>
                </article>
                <article class="row px-3 mt-1">
                  <h4 class="col-4 p-0 m-0">Referencia</h4>
                  <p class="col-8 p-0 m-0" style="border-bottom: 1px dotted">{{ movimiento.referencia }}</p>
                </article>
                <article class="row px-3 mt-1">
                  <h4 class="col-4 p-0 m-0">Descripcion</h4>
                  <p class="col-8 p-0 m-0" style="border-bottom: 1px dotted">{{ movimiento.descripcion }}</p>
                </article>
              </div>
              <div class="row mx-1 mt-3 p-2 bg-primary rounded">
                <h4 class="col-6 p-0 m-0 text-white">Cuenta</h4>
                <h4 class="col-3 p-0 m-0 text-white" style="text-align: end">Débito</h4>
                <h4 class="col-3 p-0 m-0 text-white" style="text-align: end">Crédito</h4>
              </div>
              <div v-for="item in movimiento.cuentasMovimiento" :key="item.idMovimientoCuenta" class="row mx-1 mt-1 p-2 border-bottom">
                <p class="col-6 p-0 m-0">{{ item.NombreSubCuenta.split(" ")[1] }}</p>
                <p class="col-3 p-0 m-0" style="text-align: end">$ {{ item.debito }}</p>
                <p class="col-3 p-0 m-0" style="text-align: end">$ {{ item.credito }}</p>
              </div>
              <div class="d-flex justify-content-end">
                <div class="d-flex justify-content-between w-50 mx-1 mt-3 p-2 border-bottom">
                  <p class="p-0 m-0" style="text-align: end">Importe</p>
                  <p class="p-0 m-0" style="text-align: end">{{ movimiento.importe }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex pt-4 px-5 justify-content-end">
            <vs-button size="large" danger @click.prevent="$router.push('/helex/Erp/Contabilidad/RegistrosContables/Registros')">Regresar</vs-button>
            <vs-button size="large" @click.prevent="$router.push(`/helex/Erp/Contabilidad/RegistrosContables/EditarRegistro/${$route.params.id}`)">Editar</vs-button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from "../../../../../../config/pluginInit";

export default {
  data: () => ({
    movimiento: {},
    empresa: {},
  }),
  async mounted() {
    const persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresa = persona.empresa;
    core.index();
    await this.getData();
  },
  methods: {
    async getData() {
      let movimiento = await this.$store.dispatch("hl_get", {
        path: `DEMovimientos/ObtenerMovimientoId/${this.$route.params.id}`,
      });
      this.movimiento = movimiento.data;
      this.movimiento.fecha = this.$moment.tz(movimiento.fecha, "America/Bogota").format("MM-DD-YYYY");
    },
  },
};
</script>
